const articles = {
    "chaozhou": {
        title: "Hidden gems from Chaozhou",
        published: new Date("2023-07-21"),
        updated: new Date("2023-07-21"),
        text: "/assets/articles/chaozhou/chaozhou.md",
        unlisted: true
    },
    "project-report": {
        title: "project report",
        published: new Date("2023-05-06"),
        updated: new Date("2022-05-06"),
        text: "/assets/articles/project-report/project-report.md",
        unlisted: true,
        containCode: true,
        dafny: true,
    },
    "instability": {
        title: "Instability or regression bugs: surprising behaviors from the solver/verifier",
        published: new Date("2023-05-03"),
        updated: new Date("2023-07-03"),
        text: "/assets/articles/instability/instability.md",
        containCode: true,
        dafny: true,
    },
    "dafny-persistent-BST": {
        title: "Verified persistent binary search trees using Dafny",
        published: new Date("2023-03-27"),
        updated: new Date("2023-03-27"),
        text: "/assets/articles/dafny-persistent-BST/dafny-persistent-BST.md",
        containCode: true,
        unlisted: true,
        dafny: true,
    },
    "duality-R-U": {
        title: "Duality of $\\mathbf{R}$ and $\\mathbf{U}$ in temporal logic",
        displayTitle: "Duality of R and U in temporal logic",
        published: new Date("2022-03-17"),
        updated: new Date("2022-03-17"),
        text: "/assets/articles/duality-temporal/duality-temporal.md",
        containMath: true,
    },
    "feferman-vaught-reduction-sequence": {
        title: "Lower bound of the size of reduction sequences in Feferman-Vaught theorem",
        subtitle: "Course project for 236331 Technion (Fall 2021)",
        published: new Date("2022-05-31"),
        updated: new Date("2022-05-31"),
        text: "/assets/articles/feferman-vaught-reduction-sequence/course-project.md",
        pdf: "/assets/articles/feferman-vaught-reduction-sequence/report.pdf",
    },
    "frontend-vault": {
        title: "Frontend vault",
        subtitle: "Things that helped me get better at frontend development",
        published: new Date("2022-04-27"),
        updated: new Date("2022-05-16"),
        text: "/assets/articles/frontend-vault/frontend-vault.md",
    },
    "ip-notes": {
        title: "An introduction to intellectual property",
        subtitle: "Lecture notes for 851-0732-03L ETH Zürich (Spring 2019)",
        published: new Date("2019-05-26"),
        updated: new Date("2019-12-20"),
        text: "/assets/articles/IP-notes/IP-notes.md",
    },
    "mathematical-logic-ex": {
        title: "Exercises from Mathematical Logic",
        subtitle: "Selected exercises from Mathematical Logic, 2nd by H.-D. Ebbinghaus, J. Flum, W. Thomas",
        published: new Date("2022-03-20"),
        updated: new Date("2022-03-20"),
        text: "/assets/articles/mathematical-logic/exercises.md",
        pdf: "/assets/articles/mathematical-logic/exercises.pdf",
        unlisted: true,
    },
    "number-encodings": {
        title: "Different number encodings",
        subtitle: "Peano, Church, and tree encodings",
        published: new Date("2022-04-01"),
        updated: new Date("2022-04-01"),
        text: "/assets/articles/number-encodings/number-encodings",
        containMath: true,
        unlisted: true,
    },
    "overflow-two-complement": {
        title: "How to detect overflow in two's complement",
        published: new Date("2022-03-28"),
        updated: new Date("2022-03-28"),
        text: "/assets/articles/overflow-two-complement/overflow.md",
        containMath: true,
        unlisted: true,
    },
    "postgreSQL-privileges": {
        title: "Case study: how to handle privileges in PostgreSQL",
        published: new Date("2019-08-26"),
        updated: new Date("2019-10-26"),
        text: "/assets/articles/postgreSQL/posgreSQL.md",
        containCode: true,
    },
    "SQLite-documentation": {
        title: "A simple python script to document SQLite databases",
        published: new Date("2019-12-20"),
        updated: new Date("2019-12-20"),
        text: "/assets/articles/SQLite/sqlite.md",
        containCode: true,
    },
    "persistent-avl": {
        title: "Partially and fully persistent AVL trees",
        subtitle: "Seminar report for 252-4230-00L ETH Zürich (Fall 2018)",
        published: new Date("2018-11-13"),
        updated: new Date("2018-11-13"),
        text: "/assets/articles/AVL-tree/avl-tree.md",
        pdf: "/assets/articles/AVL-tree/Persistent-AVL-trees.pdf",
    },
    "sign-extension": {
        title: "Why sign extension preserves value in two's complement",
        published: new Date("2022-03-18"),
        updated: new Date("2022-03-31"),
        text: "/assets/articles/sign-extension/sign-extension.md",
        containMath: true,
    },
    "intellectual-passion": {
        title: "On intellectual passion and its unfortunate confusion with sexual passion (a female's perspective)",
        subtitle: "Thoughts after reading Oded Goldreich's essays",
        published: new Date("2023-03-25"),
        updated: new Date("2023-03-27"),
        text: "/assets/articles/intellectual-passion/intellectual-passion.md",
    },
    "proof-sensitivity-literature": {
        title: "Proof sensitivity - curated list of literature",
        published: new Date("2023-12-05"),
        updated: new Date("2023-12-06"),
        text: "/assets/articles/proof-sensitivity/literature.md",
        unlisted: true
    }
};
export default articles;

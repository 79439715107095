import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
export default function Navbar() {
    const location = useLocation();
    return (React.createElement("header", null,
        React.createElement("div", { id: "navbar" },
            React.createElement("div", { id: "navbar-name" },
                React.createElement(Link, { to: "/" }, "Shengyu Huang")),
            React.createElement("div", { id: "navigator" },
                React.createElement(Link, { to: "/articles/" },
                    React.createElement("span", { style: {
                            textDecoration: location.pathname.includes("articles") || location.pathname === "/" ? "underline" : "none",
                            textDecorationThickness: "0.05em",
                            textUnderlineOffset: "0.1em",
                        } }, "ARTICLES")),
                React.createElement(Link, { to: "/projects/" },
                    React.createElement("span", { style: {
                            textDecoration: location.pathname.includes("projects") ? "underline" : "none",
                            textDecorationThickness: "0.05em",
                            textUnderlineOffset: "0.1em",
                        } }, "PROJECTS")),
                React.createElement(Link, { to: "/about/" },
                    React.createElement("span", { style: {
                            textDecoration: location.pathname.includes("about") ? "underline" : "none",
                            textDecorationThickness: "0.05em",
                            textUnderlineOffset: "0.1em",
                        } }, "ABOUT"))))));
}

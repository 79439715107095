import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ArticleOverview from "./ArticleOverview";
import Sorter from "./Sorter";
import articles from "assets/articles/overview";
import Article from "./Article";
export default function Articles() {
    const [mathJaxLoaded, setMathJaxLoaded] = useState(false);
    const [highlightLoaded, setHighlightLoaded] = useState(false);
    useEffect(() => {
        document.title = "Articles - Shengyu Huang";
    }, []);
    useEffect(() => {
        if (mathJaxLoaded) {
            window.MathJax.typeset();
        }
        else {
            // load MathJax
            const script = document.createElement("script");
            script.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js";
            script.onload = () => {
                setMathJaxLoaded(true);
            };
            document.body.append(script);
        }
    });
    return (React.createElement(Routes, null,
        React.createElement(Route, { index: true, element: React.createElement(AllArticles, { mathJaxLoaded: mathJaxLoaded }) }),
        React.createElement(Route, { path: ":articleId", element: React.createElement(Article, { mathJaxLoaded: mathJaxLoaded, highlightLoaded: highlightLoaded, setHighlightLoaded: setHighlightLoaded }) })));
}
function AllArticles(props) {
    const { mathJaxLoaded } = props;
    const [sortBy, setSortBy] = useState("updated");
    const [sortDirection, setSortDirection] = useState("down");
    return (React.createElement("div", { id: "articles" },
        React.createElement(Sorter, { sortBy: sortBy, setSortBy: setSortBy, sortDirection: sortDirection, setSortDirection: setSortDirection }),
        Object.entries(articles)
            .filter(([_, article]) => !article.unlisted)
            .sort((first, second) => {
            const article1 = first[1], article2 = second[1];
            if (sortBy === "published") {
                if (sortDirection === "down") {
                    return article1.published > article2.published ||
                        (article1.published.getTime() == article2.published.getTime() && article1.updated > article2.updated)
                        ? -1
                        : 1;
                }
                else {
                    return article1.published <= article2.published ? -1 : 1;
                }
            }
            else if (sortBy === "updated") {
                if (sortDirection === "down") {
                    return article1.updated > article2.updated ||
                        (article1.updated.getTime() == article2.updated.getTime() && article1.published > article2.published)
                        ? -1
                        : 1;
                }
                else {
                    return article1.updated <= article2.updated ? -1 : 1;
                }
            }
            else {
                return -1;
            }
        })
            .map(([id, article]) => (React.createElement(React.Fragment, { key: id },
            React.createElement(ArticleOverview, { article: article, id: id, mathJaxLoaded: mathJaxLoaded, sortBy: sortBy }))))));
}

import React, { useEffect } from "react";
export default function About() {
    useEffect(() => {
        document.title = "About - Shengyu Huang";
    }, []);
    return (React.createElement("div", { id: "about" },
        React.createElement("figure", { id: "profile" },
            React.createElement("img", { src: `/assets/img/profile${window.innerWidth > 500 ? "" : "-small"}.jpeg`, alt: "profile", title: "Profile photographed by Wuxing Dong" })),
        React.createElement("div", { className: "font-regular" },
            React.createElement("p", null, "Hi, I'm \u9EC4\u5347\u4FDE (hu\u00E1ng sh\u0113ng y\u00FA). I am passionate about languages, both programming and natural ones."),
            React.createElement("p", null,
                "I obtained my bachelor's degree in computer science at",
                " ",
                React.createElement("a", { href: "https://ethz.ch/de.html" }, "ETH Z\u00FCrich"),
                " ",
                "in 03.2021 with my thesis supervised by",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://dblp.org/pid/82/1212.html" }, "Liu Chih-Hung"),
                ". During my bachelor's, I have worked as a student assistant at",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.sg.ethz.ch/" }, "Chair of Systems Design"),
                " ",
                "and",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.raumplus.ethz.ch/de/home/" }, "Raum+"),
                ". From 10.2021 to 03.2022, I was at",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.technion.ac.il/en/" }, "Technion"),
                " ",
                "supervised by",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.cs.technion.ac.il/~shachari/" }, "Shachar Itzhaky"),
                ". At the moment, I am a master student at",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.epfl.ch/en/" }, "EPFL"),
                ", majoring in computer science and minoring in mathematics.")),
        React.createElement("div", { className: "font-regular" },
            React.createElement("p", null,
                "I want to use some space to list a few works/articles that have a huge impact on me in hindsight. Maybe you'll also find them interesting:",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.wisdom.weizmann.ac.il/~oded/tcs.html" }, "TCS = TOC + TOP"),
                ",",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://ncase.me/trust/" }, "The Evolution of Trust"),
                ",",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.wisdom.weizmann.ac.il/~oded/passion.html" }, "On intellectual passion and its unfortunate confusion with sexual passion (and how it may relate to issues of gender)"),
                ",",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.wisdom.weizmann.ac.il/~oded/gender.html" }, "A male's experience of being a gender minority"),
                ",",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://book.douban.com/subject/35092383/" }, "\u300A\u628A\u81EA\u5DF1\u4F5C\u4E3A\u65B9\u6CD5\u300B"),
                ", ",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.youtube.com/watch?v=z7GVHB2wiyg" }, "Ego and Math"),
                ", ",
                " ",
                " and ",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.youtube.com/watch?v=s_L-fp8gDzY" }, "What Makes People Engage With Math"),
                "."))));
}

import React, { useEffect, useRef, useState, Suspense } from "react";
import * as marked from "marked";
import ArticleDate from "./ArticleDate";
import ErrorPage from "src/components/ErrorPage";
import { useParams } from "react-router-dom";
import articles from "assets/articles/overview";
const PDFViewer = React.lazy(() => import("./PDFViewer"));
export default function Article(props) {
    const { mathJaxLoaded, highlightLoaded, setHighlightLoaded } = props;
    const { articleId } = useParams();
    const [article, setArticle] = useState();
    const [content, setContent] = useState("");
    const [loadingText, setLoadingText] = useState(true);
    const [unfound, setUnfound] = useState(false);
    const contentRef = useRef(null);
    useEffect(() => {
        const title = articleId.toLowerCase();
        try {
            const article = Object.entries(articles).find(([id, _]) => id.toLowerCase() === title)[1];
            setArticle(article);
            setUnfound(false);
        }
        catch {
            setArticle(null);
            setUnfound(true);
        }
    }, [articleId]);
    useEffect(() => {
        if (!article) {
            return;
        }
        if (article.containCode && !highlightLoaded) {
            // load highlight.js
            const script = document.createElement("script");
            script.src = "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.2.0/highlight.min.js";
            script.onload = () => {
                setHighlightLoaded(true);
            };
            document.body.append(script);
        }
        if (article.text) {
            setLoadingText(true);
        }
        else {
            setLoadingText(false);
        }
        document.title = `${article.displayTitle || article.title} - Shengyu Huang`;
    }, [article]);
    // fetch article's text
    useEffect(() => {
        if (!article || !article.text) {
            return;
        }
        const controller = new AbortController();
        contentRef.current.innerHTML = "";
        fetch(article.text, { signal: controller.signal })
            .then((res) => {
            if (controller.signal.aborted) {
                return "";
            }
            return res.text();
        })
            .then(setContent)
            .catch((e) => {
            if (!controller.signal.aborted) {
                console.log(e);
            }
        });
        return function cleanup() {
            article && controller.abort();
        };
    }, [article]);
    useEffect(() => {
        if (article && article.containMath) {
            if (mathJaxLoaded) {
                window.MathJax.typeset();
            }
        }
    });
    useEffect(() => {
        if (!article || !content || !article.text) {
            return;
        }
        function lazyLoadImages() {
            /** Lazy load all images in the article **/
            const lazyImageObserver = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        const lazyImage = entry.target;
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImageObserver.unobserve(lazyImage);
                        lazyImage.classList.remove("lazy");
                    }
                });
            });
            const lazyImages = [].slice.call(contentRef.current.querySelectorAll("img"));
            lazyImages.forEach(function (lazyImage) {
                lazyImageObserver.observe(lazyImage);
            });
        }
        if (article.containCode) {
            if (highlightLoaded) {
                // only load dafny highlighting if necessary
                if (article.dafny) {
                    hljs.registerLanguage("dafny", (() => {
                        "use strict";
                        return e => {
                            const t = /[a-zA-Z_<>]*/, s = ["bool", "char", "imap", "iset", "int", "map", "multiset", "nat", "object", "object?", "ORDINAL", "seq", "set", "array", "bc"], a = {
                                keyword: ["abstract", "allocated", "as", "assert", "assume", "break", "by", "calc", "case", "class", "codatatype", "colemma", "const", "constructor", "copredicate", "datatype", "decreases", "else", "ensures", "exists", "export", "extends", "forall", "fresh", "function", "ghost", "if", , "import", , "include", "inductive", "invariant", "is", "iterator", "label", "lemma", "match", "method", "modifies", "modify", "module", "nameonly", "new", "newtype", "old", "opened", "predicate", "print", "provides", "reads", "real", "refines", "requires", "return", "returns", "reveal", "reveals", "static", "string", "then", "this", "trait", "twostate", "type", "unchanged", "var", "while", "witness", "yield", "yields"],
                                type: s, literal: ["false", "null", "true"]
                            }, n = {
                                begin: RegExp("(?<=[^:]:[\\s\\n]*)" + t.source), scope: "type"
                            }, r = {
                                begin: "(?<!\\w)(&&|\\|\\||<==>|==>|<==|==|!=|!|>|<|>=|<=|\\+|-|\\*|/|%|>>|<<|&|\\||\\^|!!|in|!in|:=|:\\||::|=)(?!\\w)",
                                scope: "operator"
                            };
                            return { case_insensitive: !1, keywords: a,
                                contains: [e.QUOTE_STRING_MODE, e.C_LINE_COMMENT_MODE, e.NUMBER_MODE, e.COMMENT("/\\*", "\\*/", {
                                        contains: [{ scope: "doctag", begin: "@\\w+" }]
                                    }), n, r, {
                                        begin: "\\b(" + s.join("|") + ")\\b" + t.source, scope: "type"
                                    }, {
                                        begin: ["(?:" + e.IDENT_RE + "\\s+)", t, /\s*(?=\()/], scope: { 2: "title.function" },
                                        keywords: a, contains: [{ className: "params", begin: /\(/, end: /\)/, keywords: a,
                                                relevance: 0,
                                                contains: [e.QUOTE_STRING_MODE, e.C_BLOCK_COMMENT_MODE, n, r, e.NUMBER_MODE]
                                            }, e.C_LINE_COMMENT_MODE, e.C_BLOCK_COMMENT_MODE]
                                    }] };
                        };
                    })());
                }
                marked.parse(content, {
                    renderer: new marked.Renderer(),
                    highlight: function (code, lang) {
                        const language = hljs.getLanguage(lang) ? lang : "plaintext";
                        return hljs.highlight(code, { language }).value;
                    },
                    langPrefix: "hljs language-",
                }, (err, str) => {
                    contentRef.current.innerHTML = err || str;
                    setLoadingText(false);
                    lazyLoadImages();
                });
            }
        }
        else {
            marked.parse(content, (err, str) => {
                contentRef.current.innerHTML = err || str;
                setLoadingText(false);
                lazyLoadImages();
            });
        }
    }, [article, highlightLoaded, content]);
    if (unfound) {
        return React.createElement(ErrorPage, null);
    }
    else {
        return (React.createElement("div", { className: "article" },
            article ? (React.createElement("div", null,
                React.createElement("h1", null, article.title),
                React.createElement(ArticleDate, { published: article.published, updated: article.updated, sortBy: "" }))) : null,
            React.createElement("div", { style: { display: loadingText ? "block" : "none" } }, "Loading..."),
            React.createElement("div", { className: "content", ref: contentRef }),
            article && article.pdf ? (React.createElement(React.Fragment, null,
                React.createElement(Suspense, { fallback: React.createElement("span", null) },
                    React.createElement(PDFViewer, { pdfLocation: article.pdf })))) : null));
    }
}

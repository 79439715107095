import React, { useEffect } from "react";
import projects from "assets/projects";
function Project(props) {
    const { name, keywords, snapshotLocation, smallSnapshotLocation, link } = props;
    return (React.createElement("div", { className: "project" },
        React.createElement("a", { className: "project-title", href: link, target: "_blank", rel: "noopener noreferrer" },
            name,
            React.createElement("img", { src: "/assets/icon/link.svg", alt: "(link)" })),
        React.createElement("div", { className: "keywords" }, [...keywords].map((x) => (React.createElement("span", { key: x, className: "keyword" }, x)))),
        React.createElement("img", { className: "snapshot", src: window.innerWidth > 500 ? snapshotLocation : smallSnapshotLocation, alt: "snapshot of " + name })));
}
export default function Projects() {
    useEffect(() => {
        document.title = "Projects - Shengyu Huang";
    }, []);
    return (React.createElement("div", { id: "projects" }, Object.entries(projects).map(([id, project]) => (React.createElement(React.Fragment, { key: id },
        React.createElement(Project, { name: project.name, keywords: project.keywords, snapshotLocation: project.snapshotLocation, smallSnapshotLocation: project.smallSnapshotLocation, link: project.link }))))));
}

import React from "react";
export default function Sorter({ sortBy, setSortBy, sortDirection, setSortDirection }) {
    return (React.createElement("div", { id: "sorter", className: "flex-end" },
        "Sort by \u00A0",
        React.createElement("select", { name: "sort-by", value: `${sortBy}-${sortDirection}`, onChange: (event) => {
                const [sortBy, sortDirection] = event.target.value.split("-");
                setSortBy(sortBy);
                setSortDirection(sortDirection);
            } },
            React.createElement("option", { value: "updated-up" }, "Update Date \u2191"),
            React.createElement("option", { value: "updated-down" }, "Update Date \u2193"),
            React.createElement("option", { value: "published-up" }, "Publication Date \u2191"),
            React.createElement("option", { value: "published-down" }, "Publication Date \u2193"))));
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import Projects from "./Projects";
import About from "./About";
import Articles from "./Articles";
import Footer from "./Footer";
import ErrorPage from "./ErrorPage";
export default function App() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Navbar, null),
        React.createElement("main", null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/", element: React.createElement(Articles, null) }),
                React.createElement(Route, { path: "articles/*", element: React.createElement(Articles, null) }),
                React.createElement(Route, { path: "projects", element: React.createElement(Projects, null) }),
                React.createElement(Route, { path: "about", element: React.createElement(About, null) }),
                React.createElement(Route, { path: "*", element: React.createElement(ErrorPage, null) }))),
        React.createElement(Footer, null)));
}

const projects = {
    radix: {
        name: "Radix Converter",
        keywords: ["TypeScript", "React.js", "Scss", "HTML"],
        snapshotLocation: "/assets/img/radix.png",
        smallSnapshotLocation: "/assets/img/radix.png",
        link: "https://kumom.io/radix-converter/",
    },
    conway: {
        name: "Conway's Game of Life",
        keywords: ["JavaScript", "Scss", "HTML"],
        snapshotLocation: "/assets/img/conways.png",
        smallSnapshotLocation: "/assets/img/conways.png",
        link: "https://kumom.io/conways/",
    },
};
export default projects;

import React from "react";
import ArticleDate from "./ArticleDate";
import { Link } from "react-router-dom";
export default function ArticleOverview(props) {
    const { article, id, mathJaxLoaded, sortBy } = props;
    return (React.createElement("div", { className: "overview" },
        React.createElement(Link, { to: `/articles/${id}` },
            React.createElement("div", { className: "title" }, article.displayTitle && !mathJaxLoaded ? article.displayTitle : article.title)),
        article.subtitle ? React.createElement("div", { className: "subtitle" }, article.subtitle) : null,
        React.createElement(ArticleDate, { published: article.published, updated: article.updated, sortBy: sortBy })));
}

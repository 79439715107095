import React from "react";
export default function ArticleDate(props) {
    const { published, updated, sortBy } = props;
    return (React.createElement("div", { className: "date" },
        React.createElement("span", { className: `${sortBy === "published" ? "" : "grey"}` },
            "Published on ",
            published.toDateString().split(" ").slice(1).join(" ")),
        React.createElement("span", { className: "grey break" }, "|"),
        React.createElement("span", { className: `${sortBy === "updated" ? "" : "grey"}` },
            "Last updated on ",
            updated.toDateString().split(" ").slice(1).join(" "))));
}

import React from "react";
export default function Footer() {
    return (React.createElement("footer", null,
        React.createElement("hr", { id: "footer-break" }),
        React.createElement("div", { id: "links-container" },
            React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://github.com/kumom" },
                React.createElement("img", { className: "external-link", src: "/assets/icon/github.png", alt: "github-link" })),
            React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "mailto: shhuang.huangsh@gmail.com" },
                React.createElement("img", { className: "external-link", src: "/assets/icon/mail.svg", alt: "mail-icon" }))),
        React.createElement("div", null, "\u00A9 2018-2023 Shengyu Huang")));
}
